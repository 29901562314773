import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import Logo from 'images/svg/logo.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Footer: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <div className="flex w-full flex-col gap-14">
      <div className="font-tertiary-regular---8pt flex w-full flex-col justify-center gap-1 text-center text-text-gray">
        <Text tagName="span" className="w-full">
          {t('settings.form.footer.text_one')}
        </Text>
        <div className="flex w-full items-center justify-center gap-1">
          <Text tagName="span" className="">
            {t('settings.form.footer.text_two')}
          </Text>
          <div className="flex h-2 items-center">
            <img src={Logo} className="h-full" />
          </div>
          <a
            href="https://thenumberx.com/eula"
            target="_blank"
            rel="noopener noreferrer"
            className="text-text-gray hover:text-text-gray"
          >
            <Text tagName="span" className="">
              {t('settings.form.footer.link')}
            </Text>
          </a>
        </div>
      </div>
      <div className="flex w-full items-center justify-center gap-2">
        <Text tagName="span" className="font-tertiary-bold---12pt text-text-gray">
          {t('settings.form.footer.caption')}
        </Text>
        <div className="flex h-4 items-center">
          <img src={Logo} className="h-full" />
        </div>
      </div>
    </div>
  )
}

export { Footer }

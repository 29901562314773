import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import cn from 'classnames'
import { Apm, CheckoutState, PaymentType, TVat, usePaymentController } from 'client/controllers/paymentsController'
import Apple from 'images/demo/apple_pay.svg?react'
import Google from 'images/demo/google_pay.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'
import { calcVat } from 'lib/utils/calcVat'

type TProps = {
  vats: TVat[]
}

const Buttons: FC<{}> = () => {
  const {
    props: { vats },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.DemoPage)
  const { settings, setSettings } = usePaymentController()
  const selectedVat = vats?.find((item) => item.country_code === settings.vat?.value)

  const buttonAllowed = settings.cardNumber && settings.expiryDate && settings.cvc && settings.nameOnCard

  return (
    <div className="flex w-full">
      <div
        className={cn(
          'font-tertiary-bold---12pt flex h-11 w-full cursor-pointer items-center justify-center rounded',
          buttonAllowed ? 'cursor-pointer' : 'cursor-not-allowed',
        )}
        style={{ color: settings.textColor, backgroundColor: settings.buttonColor }}
        onClick={() => {
          if (buttonAllowed) {
            setSettings({ state: settings.testCard ?? CheckoutState.SUCCESS })
          }
        }}
      >
        {settings.apm.value === Apm.GLOBAL && (
          <Text tagName="span" className="">
            {settings.paymentType === PaymentType.ONE_TIME
              ? t('settings.form.button.one_time', {
                  amount: selectedVat
                    ? `€${settings.amount + calcVat(settings.amount, selectedVat.value)}`
                    : `€${settings.amount}`,
                })
              : t('settings.form.button.subscription', {
                  amount: selectedVat
                    ? `€${settings.amount + calcVat(settings.amount, selectedVat.value)}`
                    : `€${settings.amount}`,
                  duration: '30',
                })}
          </Text>
        )}
        {settings.apm.value === Apm.APPLE && (
          <div className="flex h-7 items-center" style={{ fill: settings.textColor }}>
            <Apple />
          </div>
        )}
        {settings.apm.value === Apm.GOOGLE && (
          <div className="flex h-7 items-center" style={{ fill: settings.textColor }}>
            <Google />
          </div>
        )}
      </div>
    </div>
  )
}

export { Buttons }

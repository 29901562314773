import { keysOf } from 'lib/constants/keysOf'
import lodash from 'lodash'
import { TOption } from 'types'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

const { isNil } = lodash

export enum DemoStep {
  'PLAN' = 'PLAN',
  'CHECKOUT' = 'CHECKOUT',
}

export enum PaymentType {
  'ONE_TIME' = 'one_time',
  'SUBSCRIPTION' = 'subscription',
}

export enum CheckoutState {
  'PREPARE' = 'PREPARE',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
}

export type TVat = {
  country_code: string
  title: string
  value: number
}

type TPaymentSettings = {
  paymentType: PaymentType
  isDesktop: boolean
  title: string
  state: CheckoutState
  apm: TOption
  buttonColor: string
  textColor: string
  cardNumber: string
  expiryDate: string
  cvc: string
  nameOnCard: string
  logo: {
    name: string
    url: string
  }
  testCard: CheckoutState.SUCCESS | CheckoutState.FAILED | null
  amount: number
  vat: TOption | null
  vatEnabled: boolean
}

export enum Apm {
  'GLOBAL' = 'global',
  'APPLE' = 'applepay',
  'GOOGLE' = 'googlepay',
}

export const APMS: TOption[] = [
  {
    label: 'Global',
    value: Apm.GLOBAL,
  },
  {
    label: 'Apple Pay',
    value: Apm.APPLE,
  },
  {
    label: 'Google Pay',
    value: Apm.GOOGLE,
  },
]

const DEFAULT_VALUES: {
  [key in PaymentType]: {
    title: string
  }
} = {
  [PaymentType.ONE_TIME]: {
    title: 'Payment with NumberX',
  },
  [PaymentType.SUBSCRIPTION]: {
    title: 'Subscribe to NumberX',
  },
}

const DEFAULT_SETTINGS: TPaymentSettings = {
  paymentType: PaymentType.ONE_TIME,
  isDesktop: true,
  title: DEFAULT_VALUES[PaymentType.ONE_TIME].title,
  state: CheckoutState.PREPARE,
  apm: APMS[0],
  buttonColor: '#7957FD',
  textColor: '#ffffff',
  cardNumber: '',
  expiryDate: '',
  cvc: '',
  nameOnCard: '',
  logo: {
    name: '',
    url: '',
  },
  testCard: null,
  amount: 140,
  vat: null,
  vatEnabled: false,
}

export type TCard = {
  number: string
  expiryDate: string
  cvc: string
  nameOnCard: string
}

export const TEST_CARD_NUMBERS: { [key: string]: TCard } = {
  [CheckoutState.SUCCESS]: {
    number: '1234 1234 1234 1234',
    expiryDate: '01/28',
    cvc: '123',
    nameOnCard: 'JOHN TEST',
  },
  [CheckoutState.FAILED]: {
    number: '2222 2222 2222 2222',
    expiryDate: '04/23',
    cvc: '888',
    nameOnCard: 'TEST JOHN',
  },
}

type TState = {
  settings: TPaymentSettings
  step: DemoStep
}

type TActions = {
  setSettings: (setting: Partial<TPaymentSettings>) => void
  setStep: (step: DemoStep) => void
}

const usePaymentController = create<TState & TActions>()(
  immer((set, get) => ({
    // state
    settings: DEFAULT_SETTINGS,
    step: DemoStep.PLAN,
    redirectUrl: null,
    // actions
    setStep: (step) =>
      set((state) => {
        state.step = step

        switch (step) {
          case DemoStep.CHECKOUT:
            const paymentType = get().settings.paymentType

            state.settings.title = DEFAULT_VALUES[paymentType].title

            break
          default:
        }
      }),
    setSettings: (settings) => {
      set((state) => {
        keysOf(settings).forEach((k) => {
          if (!isNil(k)) {
            // @ts-expect-error
            state.settings[k] = settings[k]
          }
        })
      })
    },
  })),
)

export { usePaymentController }
